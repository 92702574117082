<template>
  <div class="content-oc-global">
    <!-- #region Barra Gasto / Presupuesto -CATEGORIA -->
    <v-row>
      <v-col cols="12">
        <div class="content-porcentual-bar-o-c-all">
          <div class="content-porcentual-bar-o-c-info">
            <span class="txt-categorie-info-porcentual">
              Proyecto: {{ sNameBarProyect }}</span
            >
            <v-spacer />
            <span class="txt-spent-info-porcentual">
              Gasto
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" size="20" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >El gasto es el subtotal de los <br />
                  conceptos de la orden de compra</span
                >
              </v-tooltip>
              <span class="txt-spent-money-info-percentual">
                ${{ getFormatMoney(dSpentOCProyect) }} MXN
              </span>
            </span>

            <span class="txt-budget-info-porcentual">
              Presupuesto
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" size="20" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >El presupuesto es el monto <br />
                  total de la categoría</span
                >
              </v-tooltip>
              <span class="txt-budget-money-info-percentual">
                ${{ getFormatMoney(dBudgetOCProyect) }} MXN
              </span>
            </span>
          </div>
          <div class="content-porcentual-bar-o-c">
            <v-progress-linear
              class="porcentual-bar-o-c-dialog"
              height="25"
              :value="dPercentOCProyect"
              buffer-value="100"
              :color="sColorOCProyect"
            >
              ${{ getFormatMoney(dSpentOCProyect) }} MXN / ${{
                getFormatMoney(dBudgetOCProyect)
              }}
              MXN
            </v-progress-linear>
            <p class="txt-porcent-oc-dialog">
              {{ getPercentBar(dSpentOCProyect, dBudgetOCProyect) }}%
            </p>
          </div>
        </div>
      </v-col>
      <!-- <v-col cols="12">
        <div class="content-porcentual-bar-o-c-dialog">
          <div class="content-porcentual-bar-dialog">
            <p class="txt-spent-budget-o-c-dialog">
              Vista previa de gasto/presupuesto
            </p>
            <v-progress-linear
              class="porcentual-bar-o-c-dialog"
              height="25"
              :value="dPercentOCProyect"
              buffer-value="100"
              :color="sColorOCProyect"
            >
              ${{ getFormatMoney(dSpentOCProyect) }} MXN / ${{
                getFormatMoney(dBudgetOCProyect)
              }}
              MXN
            </v-progress-linear>
            <p class="txt-porcent-oc-dialog">
              {{ getPercentBar(dSpentOCProyect, dBudgetOCProyect) }}%
            </p>
          </div>
        </div>
      </v-col> -->
    </v-row>
    <!-- #endregion Barra Gasto / Presupuesto -CATEGORIA -->

    <!-- #region Barra Gasto / Presupuesto -CATEGORIA -->
    <v-row v-if="bModuleOCGestion && bEnviada">
      <v-col cols="12">
        <div class="content-porcentual-bar-o-c-all">
          <div class="content-porcentual-bar-o-c-info">
            <span class="txt-categorie-info-porcentual"
              >Categoría: {{ sNameBarCategorie }}</span
            >
            <v-spacer />
            <span class="txt-spent-info-porcentual">
              Gasto
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" size="20" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >El gasto es el subtotal de los <br />
                  conceptos de la orden de compra</span
                >
              </v-tooltip>
              <span class="txt-spent-money-info-percentual">
                ${{ getFormatMoney(dSpentOCCategorie) }} MXN
              </span>
            </span>

            <span class="txt-budget-info-porcentual">
              Presupuesto
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" size="20" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >El presupuesto es el monto <br />
                  total de la categoría</span
                >
              </v-tooltip>
              <span class="txt-budget-money-info-percentual">
                ${{ getFormatMoney(dBudgetOCCategorie) }} MXN
              </span>
            </span>
          </div>
          <div class="content-porcentual-bar-o-c">
            <v-progress-linear
              class="porcentual-bar-o-c-dialog"
              height="25"
              :value="dPercentOCCategorie"
              buffer-value="100"
              :color="sColorOCCategorie"
            >
              ${{ getFormatMoney(dSpentOCCategorie) }} MXN / ${{
                getFormatMoney(dBudgetOCCategorie)
              }}
              MXN
            </v-progress-linear>
            <p class="txt-porcent-oc-dialog">
              {{ getPercentBar(dSpentOCCategorie, dBudgetOCCategorie) }}%
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- #endregion Barra Gasto / Presupuesto -CATEGORIA -->

    <!-- #region infromation general OC -->
    <v-row>
      <!-- #region ID component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="AddOrEdit">
        <div class="content-txt-result-global">
          <p class="txt-title-global">ID</p>
          <p class="txt-result-global">
            {{ oItemOrderOrigin.sPurchaseOrderId }}
          </p>
        </div>
      </v-col>
      <!-- #endregion ID component -->

      <!-- #region Proyect component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <div class="content-txt-result-global">
          <p class="txt-title-global">Proyecto</p>
          <p class="txt-result-global" v-if="!bModifyOrder && AddOrEdit">
            {{ oItemOrderOrigin.sProjectName }}
          </p>
          <v-select
            v-else
            v-model="sIdProyect"
            :loading="bLoadingProyect"
            item-text="sName"
            item-value="sId"
            :items="aProyect"
            class="custom-placeholder"
            @change="getCategories"
            :hide-details="true"
            no-data-text="Sin información"
          >
          </v-select>
        </div>
      </v-col>
      <!-- #endregion Proyect component -->

      <!-- #region Categorie component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <div class="content-txt-result-global">
          <p class="txt-title-global">
            Categoría
          </p>
          <div v-if="bModuleOCGestion">
            <v-select
              v-model="sIdCategorie"
              :loading="bLoadingCategorie"
              :disabled="!sIdProyect"
              item-text="sName"
              item-value="sId"
              :items="aCategorie"
              class="custom-placeholder"
              :hide-details="true"
              no-data-text="Sin información"
            >
            </v-select>
          </div>
          <div v-else>
            <p class="txt-result-global" v-if="!bModifyOrder && AddOrEdit">
              {{ oItemOrderOrigin.sCategoryName }}
            </p>
            <v-select
              v-else
              v-model="sIdCategorie"
              :loading="bLoadingCategorie"
              :disabled="!sIdProyect"
              item-text="sName"
              item-value="sId"
              :items="aCategorie"
              class="custom-placeholder"
              :hide-details="true"
              no-data-text="Sin información"
            >
            </v-select>
          </div>
        </div>
      </v-col>
      <!-- #endregion Categorie component -->

      <!-- #region Provider component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <div class="content-txt-result-global">
          <p class="txt-title-global">Proveedor</p>
          <p class="txt-result-global" v-if="!bModifyOrder && AddOrEdit">
            {{ oItemOrderOrigin.sSupplierName }}
          </p>
          <v-select
            v-else
            v-model="sIdProvider"
            :loading="bLoadingProvider"
            :disabled="bDisabledProvider"
            item-text="sName"
            item-value="sId"
            :items="aProvider"
            class="custom-placeholder"
            :hide-details="true"
            no-data-text="Sin información"
          >
          </v-select>
        </div>
      </v-col>
      <!-- #endregion Provider component -->

      <!-- #region Payment Method component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <div class="content-txt-result-global">
          <p class="txt-title-global">Método de pago</p>
          <p class="txt-result-global" v-if="!bModifyOrder && AddOrEdit">
            {{ oItemOrderOrigin.sPaymentMethod }}
          </p>
          <v-select
            v-else
            v-model="sIdPaymentMethod"
            :loading="bLoadingPaymentMethod"
            :disabled="bDisabledPaymentMethod"
            item-text="sName"
            item-value="sId"
            :items="aPaymentMethod"
            class="custom-placeholder"
            :hide-details="true"
            no-data-text="Sin información"
          >
          </v-select>
        </div>
      </v-col>
      <!-- #endregion Payment Method component -->

      <!-- #region CreatedAt component -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="AddOrEdit">
        <div class="content-txt-result-global">
          <p class="txt-title-global">Fecha de creación</p>
          <p class="txt-result-global">
            {{ oItemOrderOrigin.tDate }}
          </p>
        </div>
      </v-col>
      <!-- #endregion CreatedAt component -->

      <!-- #region archivo de cotizacion -->
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="bEditFile">
        <div>
          <p class="txt-title-global">Archivo de cotización</p>
          <div v-if="!bModifyOrder && AddOrEdit">
            <span
              v-if="!disableBtnDownloadDoc"
              @click="setDownloadFile()"
              class="txt-view-file"
              >Ver cotización</span
            >
            <span v-else class="txt-empty-global">
              "Sin cotización"
            </span>
          </div>
          <div v-else>
            <div v-if="bFile" class="content-file-oc">
              <span @click="setDownloadFile()" class="txt-view-file"
                >Ver cotización</span
              >
              <v-spacer></v-spacer>
              <v-btn @click="setDeleteFile" icon>
                <span class="mdi mdi-close mdi-close-file" />
              </v-btn>
            </div>
            <v-file-input
              v-else
              id="idFileInput"
              ref="rFileInput"
              accept="application/pdf"
              placeholder="Agregar cotización"
              v-model="filelist"
              truncate-length="50"
              hide-details
              prepend-icon=""
              append-icon="mdi-paperclip"
              :hide-details="true"
            >
              <template slot="append">
                <v-icon @click="setFileInput" class="mdi-paperclip-file">
                  mdi-paperclip</v-icon
                >
              </template>
            </v-file-input>
          </div>
        </div>
      </v-col>
      <v-col cols="8"></v-col>
      <!-- #endregion archivo de cotizacion -->

      <!-- #region Notes to provider component -->
      <v-col
        cols="12"
        :sm="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :md="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :lg="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :xl="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
      >
        <div class="content-txt-result-global">
          <p class="txt-title-global">Notas para el proveedor</p>
          <p v-if="!bModifyOrder && AddOrEdit">
            <span v-if="oItemOrderOrigin.sComments" class="txt-result-global">
              {{ oItemOrderOrigin.sComments }}
            </span>
            <span v-else class="txt-empty-global">
              “No hay notas adicionales para esta orden de compra”.
            </span>
          </p>
          <v-textarea
            v-else
            v-model="sProviderNote"
            rows="2"
            no-resize
            :hide-details="true"
          ></v-textarea>
        </div>
      </v-col>
      <!-- #endregion Notes to provider component -->

      <!-- #region internal notes component -->
      <v-col
        cols="12"
        :sm="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :md="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :lg="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
        :xl="!AddOrEdit ? '12' : bModifyOrder ? '12' : '6'"
      >
        <div class="content-txt-result-global">
          <p class="txt-title-global">
            Notas internas
            <span>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" size="20" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span>Notas no visibles por el proveedor.</span>
              </v-tooltip>
            </span>
          </p>
          <p v-if="!bModifyOrder && AddOrEdit">
            <span
              v-if="oItemOrderOrigin.sPrivateComments"
              class="txt-result-global"
            >
              {{ oItemOrderOrigin.sPrivateComments }}
            </span>
            <span v-else class="txt-empty-global">
              “No hay notas internas para esta orden de compra”.
            </span>
          </p>
          <v-textarea
            v-else
            v-model="sInternalNote"
            rows="2"
            no-resize
            :hide-details="true"
          ></v-textarea>
        </div>
      </v-col>
      <!-- #endregion internal notes component -->

      <div class="divider-global" v-if="bAprobada || bRecibidoParcial"></div>

      <!-- #region Confrim information -->
      <!-- <v-col cols="12" v-if="bEditMaterials">
        <div
          v-if="bAprobada || bRecibidoParcial"
          class="status-content-flex mt-10"
          style="white-space: nowrap"
        >
          <div class="status-content-fixed"></div>
          <div
            v-if="bRecibidoParcial || bAprobada"
            class="status-content-restant"
            style="text-align: right"
          >
            <v-btn
              width="200"
              color="primary"
              class="btn-primary-global"
              v-show="!expand2"
              @click="expand2 = !expand2"
              >Confirmar recepción
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="!expand2"
                  style="top: -30px; cursor: pointer"
                  v-bind="attrs"
                  v-on="on"
                  color="blue darken-1 "
                  >mdi-alert-circle</v-icon
                >
              </template>
              <span>
                <p class="font-weight-bold">
                  Al confirmar la recepción se da por hecho que los materiales
                  fueron recibidos correctamente y no podrán ser más ya
                  modificados.
                </p>
                <p>
                  Nota: En caso de que la orden de compra tenga un método de
                  pago diferente a crédito, pasará a pagado automáticamente.
                </p></span
              >
            </v-tooltip>

            <v-fab-transition>
              <div v-show="expand2">
                <v-btn
                  @click="getMaterialCompleto"
                  class="ma-2"
                  color="light-green"
                  dark
                >
                  Confirmar
                  <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
                <v-btn
                  @click="expand2 = !expand2"
                  class="ma-2"
                  color="red darken-1"
                  dark
                >
                  Cancelar
                  <v-icon dark right> mdi-cancel </v-icon>
                </v-btn>
              </div>
            </v-fab-transition>
          </div>
        </div>
      </v-col> -->
      <!-- #endregion Confrim information -->
    </v-row>
    <!-- #endregion infromation general OC -->

    <!-- #region table materials -->
    <v-row>
      <!-- #region start item comentarios de rechazo -->
      <v-row v-if="bCorreciones">
        <v-col>
          <div class="d-flex">
            <p class="title-details-odc">*Comentarios de rechazo:</p>
            <span style="color: #ff7d7d; margin-left: 5px">
              {{ comnetariosRechazo ? comnetariosRechazo : "Sin comentarios." }}
            </span>
          </div>
        </v-col>
      </v-row>
      <!-- #endregion start item comentarios de rechazo -->

      <v-col cols="12" sm="12">
        <v-card class="content-card-table-material-oc-global">
          <!-- #region Tabla de materiales readonly -->
          <v-simple-table
            v-if="
              !bModifyOrder &&
                (bEnviada ||
                  bAprobada ||
                  bRecibida ||
                  bRecibidoParcial ||
                  bPagada)
            "
            fixed-header
            class="simple-table-materials"
          >
            <template>
              <thead v-if="!bLoading">
                <tr>
                  <!-- <th
                    class="content-Nowrap-global"
                    v-if="bPagada || bRecibida || bRecibidoParcial || bAprobada"
                  >
                    Recibido

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="top: 0px; cursor: pointer;font-size: 15px;"
                          v-bind="attrs"
                          v-on="on"
                          color="blue darken-1"
                          >mdi-alert-circle</v-icon
                        >
                      </template>
                      <span>Marcar o desmarcar los materiales recibidos.</span>
                    </v-tooltip>
                  </th> -->
                  <th class="text-start">
                    Concepto
                  </th>
                  <th class="text-end">
                    Cantidad
                  </th>
                  <th
                    class="text-end"
                    v-if="bPagada || bRecibida || bRecibidoParcial || bAprobada"
                  >
                    Cantidad recibida
                  </th>
                  <th
                    class="text-center"
                    v-if="bPagada || bRecibida || bRecibidoParcial || bAprobada"
                  >
                    Fecha de recepción
                  </th>
                  <th class="text-center">
                    Unidad de medida
                  </th>
                  <th class="text-end">
                    Precio unitario
                  </th>
                  <th class="text-end">
                    Importe
                  </th>
                  <th v-if="bModuleOC && (bRecibidoParcial || bAprobada)">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody v-if="!bLoading">
                <tr
                  v-for="(item, index) in itemsRequestPerMaterial"
                  :key="index"
                >
                  <!-- #region campo de concepto -->
                  <td class="text-start">
                    {{ item.sMaterialName }}
                  </td>
                  <!-- #endregion campo de concepto -->

                  <!-- #region campo de cantidad -->
                  <td class="text-end">
                    {{ item.dQuantity }}
                  </td>
                  <!-- #endregion campo de cantidad -->

                  <!-- #region campo de canditad recibida -->
                  <td
                    class="text-end td-dReceivedAmount"
                    v-if="bPagada || bRecibida || bRecibidoParcial || bAprobada"
                  >
                    <div class="d-flex justify-end">
                      <v-text-field
                        v-if="bModuleOC && (bRecibidoParcial || bAprobada)"
                        type="number"
                        v-model="item.dReceivedAmount"
                        solo
                        flat
                        class="input-filled-global w-100"
                        placeholder="Cantidad"
                        :step="0.01"
                        hide-details
                        dense
                        @change="validateReceivedAmount(item)"
                      >
                      </v-text-field>
                      <!-- <v-text-filed-currency
                        v-if="bModuleOC && (bRecibidoParcial || bAprobada)"
                        v-model="item.dReceivedAmount"
                        :options="oOptionUnit"
                        solo
                        flat
                        sClass="input-filled-global w-100"
                        placeholder="Cantidad"
                        hide-details
                        dense
                        @change="validateReceivedAmount(item)"
                      /> -->
                      <span v-else>
                        {{ item.dReceivedAmount }}
                      </span>
                    </div>
                  </td>
                  <!-- #endregion campo de canditad recibida -->

                  <!-- #region campo de fecha de recepcion -->
                  <td
                    class="text-center td-dReceivedDate"
                    v-if="bPagada || bRecibida || bRecibidoParcial || bAprobada"
                  >
                    <div class="d-flex justify-center">
                      <v-menu
                        v-if="bModuleOC && (bRecibidoParcial || bAprobada)"
                        ref="bMenuFinal"
                        v-model="item.bMenuFinal"
                        :close-on-content-click="false"
                        :return-value="item.dReceivedDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            readonly
                            class="custom-placeholder w-150"
                            dense
                            v-model="item.dReceivedDate"
                            outlined
                            hide-details
                            color="primary"
                            append-icon="mdi-calendar"
                            @click:clear="item.dReceivedDate = null"
                            placeholder="Fecha recepción"
                            persistent-hint
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="yellow darken-2"
                          header-color="primary"
                          v-model="item.dReceivedDate"
                          no-title
                          locale="es-MX"
                          @input="item.bMenuFinal = false"
                        ></v-date-picker>
                      </v-menu>
                      <span v-else>
                        {{ item.dReceivedDate }}
                      </span>
                    </div>
                  </td>
                  <!-- #endregion campo de fecha de recepcion -->

                  <!-- #region campo de unidad de medida -->
                  <td class="text-center">{{ item.sMeasureUnitName }}</td>
                  <!-- #endregion campo de unidad de medida -->

                  <!-- #region campo de precio unitario -->
                  <td class="text-end" style="white-space: nowrap">
                    ${{ mask(item.dUnitPrice) }} MXN
                  </td>
                  <!-- #endregion campo de precio unitario -->

                  <!-- #region campo de importe  -->
                  <td class="text-end" style="white-space: nowrap">
                    ${{ sumatoriaImporte(item) }} MXN
                  </td>
                  <!-- #endregion campo de importe  -->

                  <!-- #region campo de acciones -->
                  <td
                    v-if="bModuleOC && (bRecibidoParcial || bAprobada)"
                    class="text-left"
                    style="white-space: nowrap"
                  >
                    <v-btn icon color="light-green" @click="upDateData(item)">
                      <v-icon>mdi-clipboard-check-multiple</v-icon>
                    </v-btn>
                  </td>
                  <!-- #endregion campo de acciones -->
                </tr>
              </tbody>
              <div v-show="bLoading">
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <div class="content-text-loading">
                  <p class="text-loading">
                    Cargando... Espere por favor
                  </p>
                </div>
              </div>
              <tfoot>
                <tr>
                  <td colspan="7">
                    <!-- #region subtotal prices -->
                    <div>
                      <v-row>
                        <v-col class="text-end" cols="6">
                          <p
                            class="txt-subtotal-global"
                            v-if="
                              bAprobada ||
                                bRecibida ||
                                bRecibidoParcial ||
                                bPagada
                            "
                          >
                            SUBTOTAL RECIBIDO
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMaterialesRecibidos() }} MXN</span
                            >
                          </p>
                        </v-col>
                        <v-col class="text-end" cols="6">
                          <p class="txt-subtotal-global">
                            SUBTOTAL
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMateriales() }} MXN</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- #endregion subtotal prices -->
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <!-- #endregion Tabla de materiales readonly -->

          <!-- #region Tabla de materiales editable(Update bReceived)  -->
          <v-simple-table
            class="simple-table-materials"
            v-if="
              bModifyOrder &&
                (bEnviada ||
                  bAprobada ||
                  bRecibida ||
                  bRecibidoParcial ||
                  bPagada)
            "
            fixed-header
          >
            <template>
              <thead v-if="!bLoading">
                <tr>
                  <th class="text-start">
                    Concepto
                  </th>
                  <th class="text-end">
                    Cantidad
                  </th>
                  <th class="text-center">
                    Unidad de medida
                  </th>
                  <th class="text-end">
                    Precio unitario
                  </th>
                  <th class="text-end">
                    Importe
                  </th>
                </tr>
              </thead>
              <tbody v-if="!bLoading">
                <tr
                  v-for="(item, index) in itemsRequestPerMaterial"
                  :key="index"
                >
                  <!-- #region campo de  concepto -->
                  <td class="text-start">
                    <v-text-field
                      v-if="!bAdvancePurchase"
                      v-model="item.sMaterialName"
                      class="input-filled-global"
                      placeholder="Concepto"
                      solo
                      flat
                      hide-details
                      dense
                    ></v-text-field>
                    <span v-else>
                      {{ item.sMaterialName }}
                    </span>
                  </td>
                  <!-- #endregion campo de  concepto -->

                  <!-- #region campo de cantidad  -->
                  <td class="text-end">
                    <div class="d-flex justify-end">
                      <v-text-field
                        type="number"
                        v-model="item.dQuantity"
                        :options="oOptionUnit"
                        solo
                        flat
                        class="input-filled-global w-100"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      >
                      </v-text-field>
                      <!-- <v-text-filed-currency
                        v-model="item.dQuantity"
                        :options="oOptionUnit"
                        solo
                        flat
                        sClass="input-filled-global w-100"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      /> -->
                    </div>
                  </td>
                  <!-- #endregion campo de cantidad  -->

                  <!-- #region campo de unidad de medida -->
                  <td class="text-center">
                    <div class="d-flex justify-center">
                      <v-select
                        v-if="!bAdvancePurchase"
                        v-model="item.sMeasureUnitId"
                        item-text="sName"
                        item-value="sMeasureUnitId"
                        :items="aUnitsMeasure"
                        placeholder="Unidad de medida"
                        solo
                        flat
                        class="input-filled-global w-150"
                        hide-details
                        dense
                        no-data-text="Sin información"
                      >
                      </v-select>
                      <span v-else>{{ item.sMeasureUnitName }}</span>
                    </div>
                  </td>
                  <!-- #endregion campo de unidad de medida -->

                  <!-- #region campo de precio unitario -->
                  <td class="text-end">
                    <div class="d-flex justify-end">
                      <v-text-filed-currency
                        v-if="!bAdvancePurchase"
                        v-model="item.dUnitPrice"
                        :options="oOptionsCurrency"
                        solo
                        flat
                        sClass="input-filled-global w-180"
                        placeholder="Precio unitario"
                        hide-details
                        dense
                      />
                      <span v-else>{{ getFormatMoney(item.dUnitPrice) }}</span>
                    </div>
                  </td>
                  <!-- #endregion campo de precio unitario -->

                  <!-- #region campo de importe  -->
                  <td class="text-end content-Nowrap-global">
                    ${{ sumatoriaImporte(item) }} MXN
                  </td>
                  <!-- #endregion campo de importe  -->
                </tr>
              </tbody>
              <div v-show="bLoading">
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <div class="content-text-loading">
                  <p class="text-loading">
                    Cargando... Espere por favor
                  </p>
                </div>
              </div>
              <br />
              <tfoot v-if="!bLoading">
                <tr>
                  <td colspan="5">
                    <!-- #region subtotal prices -->
                    <div>
                      <v-row>
                        <v-col class="text-end" cols="6">
                          <p
                            class="txt-subtotal-global"
                            v-if="
                              bRecibida ||
                                bAprobada ||
                                bRecibidoParcial ||
                                bPagada
                            "
                          >
                            SUBTOTAL RECIBIDO
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMaterialesRecibidos() }} MXN</span
                            >
                          </p>
                        </v-col>
                        <v-col class="text-end" cols="6">
                          <p class="txt-subtotal-global">
                            SUBTOTAL
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMateriales() }} MXN</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- #endregion subtotal prices -->
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <!-- #endregion Tabla de materiales editable(Update bReceived)   -->

          <!-- #region Tabla de materiales editable(Update All materials)  -->
          <v-simple-table
            class="simple-table-materials"
            v-if="bCorreciones || bBorrador || !AddOrEdit"
            fixed-header
          >
            <template>
              <thead v-if="!bLoading">
                <tr>
                  <th class="text-start">
                    Concepto
                  </th>
                  <th class="text-end">
                    Cantidad {{ bAdvancePurchase ? "/ Inventario" : "" }}
                  </th>
                  <th class="text-center">
                    Unidad de medida
                  </th>
                  <th class="text-end">
                    Precio unitario
                  </th>
                  <th class="text-end">
                    Importe
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody v-if="!bLoading">
                <tr
                  v-for="(item, index) in itemsRequestPerMaterial"
                  :key="index"
                >
                  <td class="text-start">
                    <v-text-field
                      v-if="!bAdvancePurchase"
                      v-model="item.sMaterialName"
                      class="input-filled-global"
                      placeholder="Concepto"
                      solo
                      flat
                      hide-details
                      dense
                    ></v-text-field>
                    <span v-else>
                      {{ item.sMaterialName }}
                    </span>
                  </td>

                  <td class="text-end">
                    <div class="d-flex justify-end" v-if="!bAdvancePurchase">
                      <v-text-field
                        type="number"
                        v-model="item.dQuantity"
                        solo
                        flat
                        class="input-filled-global w-150"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      >
                      </v-text-field>
                      <!-- <v-text-filed-currency
                        v-model="item.dQuantity"
                        :options="oOptionUnit"
                        solo
                        flat
                        sClass="input-filled-global w-150"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      /> -->
                    </div>
                    <span v-else>
                      {{ item.dQuantity }} / {{ item.sInventory }}
                    </span>
                  </td>

                  <td class="text-center">
                    <div class="d-flex justify-center" v-if="!bAdvancePurchase">
                      <v-select
                        v-model="item.sMeasureUnitId"
                        item-text="sName"
                        item-value="sMeasureUnitId"
                        :items="aUnitsMeasure"
                        placeholder="Unidad de medida"
                        solo
                        flat
                        class="input-filled-global w-150"
                        hide-details
                        dense
                        no-data-text="Sin información"
                      >
                      </v-select>
                    </div>
                    <span v-else>
                      {{ item.sMeasureUnitName }}
                    </span>
                  </td>

                  <td class="text-end">
                    <div class="d-flex justify-end" v-if="!bAdvancePurchase">
                      <v-text-filed-currency
                        v-model="item.dUnitPrice"
                        :options="oOptionsCurrency"
                        solo
                        flat
                        class="input-filled-global w-180"
                        placeholder="Precio unitario"
                        hide-details
                        dense
                      />
                    </div>
                    <span v-else>
                      {{ item.dUnitPrice }}
                    </span>
                  </td>

                  <td class="text-end content-Nowrap-global">
                    ${{ sumatoriaImporte(item) }} MXN
                  </td>

                  <td>
                    <v-icon color="red" @click="deleteItemMaterial(index)"
                      >mdi-close</v-icon
                    >
                  </td>
                </tr>
              </tbody>
              <div v-show="bLoading">
                <v-progress-linear
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <div class="content-text-loading">
                  <p class="text-loading">
                    Cargando... Espere por favor
                  </p>
                </div>
              </div>
              <br />
              <tfoot v-if="!bLoading">
                <tr>
                  <!-- #region campo de concepto -->
                  <td>
                    <v-text-field
                      v-if="!bAdvancePurchase"
                      v-model="sConceptAdd"
                      class="input-filled-global"
                      placeholder="Concepto"
                      solo
                      flat
                      hide-details
                      dense
                    ></v-text-field>
                    <v-select
                      v-else
                      v-model="sAdvancePurchase"
                      item-text="sName"
                      item-value="sId"
                      :items="aAdvancePurchase"
                      class="input-global mt-1"
                      background-color="#FAFAFA"
                      dense
                      outlined
                      color="primary"
                      placeholder="Concepto"
                      no-data-text="Sin información"
                    >
                    </v-select>
                  </td>
                  <!-- #endregion campo de concepto -->

                  <!-- #region campo de cantidad -->
                  <td>
                    <div class="d-flex justify-center">
                      <v-text-field
                        type="number"
                        v-model="sQuantityAdd"
                        solo
                        flat
                        class="input-filled-global w-200"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      >
                      </v-text-field>
                      <!-- <v-text-filed-currency
                        v-model="sQuantityAdd"
                        :options="oOptionUnit"
                        solo
                        flat
                        sClass="input-filled-global w-200"
                        placeholder="Cantidad"
                        hide-details
                        dense
                      /> -->
                    </div>
                  </td>
                  <!-- #endregion campo de cantidad -->

                  <!-- #region campo unidad de medida -->
                  <td>
                    <div class="d-flex justify-center">
                      <v-select
                        v-if="!bAdvancePurchase"
                        v-model="sUnitMeasureAdd"
                        item-text="sName"
                        item-value="sMeasureUnitId"
                        :items="aUnitsMeasure"
                        placeholder="Unidad de medida"
                        solo
                        flat
                        class="input-filled-global w-150"
                        hide-details
                        dense
                        no-data-text="Sin información"
                      >
                      </v-select>
                      <span v-else>
                        {{
                          oAdvancePurchase.sMeasureUnitName
                            ? oAdvancePurchase.sMeasureUnitName
                            : '"No seleccionado aún"'
                        }}
                      </span>
                    </div>
                  </td>
                  <!-- #endregion campo unidad de medida -->

                  <!-- #region campo precio unitario -->
                  <td>
                    <div class="d-flex justify-center">
                      <v-text-filed-currency
                        v-if="!bAdvancePurchase"
                        v-model="dUnitPriceAdd"
                        :options="oOptionsCurrency"
                        solo
                        flat
                        sClass="input-filled-global w-180"
                        placeholder="Precio unitario"
                        hide-details
                        dense
                      />
                      <span v-else>
                        $
                        {{
                          oAdvancePurchase.dUnitPrice
                            ? oAdvancePurchase.dUnitPrice
                            : "0.00"
                        }}
                        MXN
                      </span>
                    </div>
                  </td>
                  <!-- #endregion campo precio unitario -->

                  <!-- #region campo boton de agregar concepto -->
                  <td class="text-center" colspan="2">
                    <v-btn
                      color="primary"
                      width="100"
                      class="btn-primary-global mr-2"
                      :disabled="!validateAnadir"
                      @click="setMaterial"
                      >Añadir</v-btn
                    >
                    <br />
                  </td>
                  <!-- #endregion campo boton de agregar concepto -->
                </tr>

                <tr>
                  <td colspan="5">
                    <!-- #region subtotal prices -->
                    <div>
                      <v-row>
                        <v-col class="text-end" cols="6">
                          <p
                            class="txt-subtotal-global"
                            v-if="
                              bRecibida ||
                                bAprobada ||
                                bRecibidoParcial ||
                                bPagada
                            "
                          >
                            SUBTOTAL RECIBIDO
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMaterialesRecibidos() }} MXN</span
                            >
                          </p>
                        </v-col>
                        <v-col class="text-end" cols="6">
                          <p class="txt-subtotal-global">
                            SUBTOTAL
                            <span style="color: #63e3b2; margin-left: 10px"
                              >${{ sumatoriaMateriales() }} MXN</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- #endregion subtotal prices -->
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <!-- #endregion Tabla de materiales editable(Update All materials)  -->
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="bAprobada || bRecibidoParcial">
      <v-col cols="12" md="12">
        <div class="d-flex justify-end">
          <p>
            Importante verificar "fechas y cantidades" de recepción de los
            materiales.
          </p>
        </div>
      </v-col>
    </v-row>
    <!-- #endregion table materials -->

    <!-- #region buttons section -->

    <v-row v-if="bModuleProyect">
      <v-col cols="12" sm="6">
        <v-btn
          v-if="bModifyOrder || !AddOrEdit"
          @click="setCancelEditInformation"
          color="black"
          width="150"
          outlined
          class="btn-secundary-global mr-2 mt-2"
          >Cancelar
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <div class="d-flex justify-end" v-if="bAdminPermission">
          <v-btn
            @click="setEditInformation"
            color="primary"
            :loading="bLoadingEditInformation"
            :disabled="bLoadingEditInformation"
            width="200"
            class="btn-primary-global ml-2 mt-2"
          >
            {{ bModifyOrder ? "Guardar Información" : "Editar información" }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="bModuleProvider">
      <v-col cols="12" sm="4">
        <v-btn
          v-if="bModifyOrder || !AddOrEdit"
          @click="setCancelEditInformation"
          color="black"
          width="150"
          outlined
          class="btn-secundary-global mr-2 mt-2"
          >Cancelar
        </v-btn>
        <!-- <v-btn
          color="black"
          width="150"
          outlined
          class="btn-secundary-global mr-2 mt-2"
          >Cerrar
        </v-btn> -->
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <div>
          <v-btn
            @click="downloadOrdenCompra"
            :loading="loadingButtonOrdenCompra"
            color="primary"
            width="200"
            class="btn-primary-global mr-2"
          >
            Descargar O.C.
          </v-btn>
          <!-- <v-btn
            width="190"
            color="primary"
            class="btn-primary-global"
            :disabled="disableBtnDownloadDoc"
            @click="downloadCotizacion"
          >
            Descargar cotización
          </v-btn> -->

          <v-btn
            v-if="bAdminPermission"
            @click="setEditInformation"
            :loading="bLoadingEditInformation"
            :disabled="bLoadingEditInformation"
            color="primary"
            width="200"
            class="btn-primary-global ml-2 "
          >
            {{ bModifyOrder ? "Guardar Información" : "Editar información" }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="bModuleOC">
      <v-col cols="12">
        <div class="content-btn-actions-oc">
          <!-- #region btn cancel -->
          <v-btn
            v-if="bModifyOrder || !AddOrEdit"
            @click="setCancelEditInformation"
            color="black"
            width="150"
            outlined
            class="btn-secundary-global mr-2 mt-2"
            >Cancelar
          </v-btn>
          <!-- #endregion btn cancel -->

          <!-- #region btn delete -->
          <v-btn
            v-if="statusOrder === 'Borrador'"
            width="150"
            outlined
            color="red"
            class="btn-secundary-global mr-2 mt-2"
            @click="eliminarOrder"
            >Eliminar orden
            <v-icon size="15">mdi-delete</v-icon>
          </v-btn>
          <!-- #endregion btn delete -->

          <!-- <v-btn
            color="black"
            width="150"
            outlined
            class="btn-secundary-global mr-2 mt-2"
            >Cerrar
          </v-btn> -->

          <v-spacer />
          <!-- #region guardar como borrador -->
          <v-btn
            v-if="statusOrder === 'Borrador' || !AddOrEdit"
            color="black"
            width="200"
            outlined
            class="btn-secundary-global mr-2 mt-2"
            @click="setBorradorOAprobacion(2)"
            :disabled="butttonGuardarBorrador"
            :loading="butttonGuardarBorrador"
          >
            Guardar como borrador
          </v-btn>
          <!-- #endregion guardar como borrador -->

          <!-- #region Enviar por correo -->
          <v-btn
            v-if="
              statusOrder === 'Aprobada' ||
                statusOrder === 'Recibida' ||
                statusOrder === 'Recibido Parcial' ||
                statusOrder === 'Pagada'
            "
            width="190"
            color="primary"
            class="btn-primary-global mr-2 mt-2"
            @click="sendEmail"
            :loading="loadingSendEmail"
            :disabled="loadingSendEmail"
          >
            Enviar por correo
          </v-btn>
          <!-- #endregion Enviar por correo -->

          <!-- #region Descargar cotización -->
          <!-- <v-btn
            v-if="
              statusOrder === 'Enviada' ||
                statusOrder === 'Aprobada' ||
                statusOrder === 'Recibida' ||
                statusOrder === 'Recibido Parcial' ||
                statusOrder === 'Pagada'
            "
            width="190"
            color="primary"
            class="btn-primary-global mr-2 mt-2"
            :disabled="disableBtnDownloadDoc"
            @click="downloadCotizacion"
          >
            Descargar cotización
          </v-btn> -->
          <!-- #endregion Descargar cotización -->

          <!-- #region Descargar O.C. -->
          <v-btn
            v-if="
              statusOrder === 'Aprobada' ||
                statusOrder === 'Recibida' ||
                statusOrder === 'Recibido Parcial' ||
                statusOrder === 'Pagada'
            "
            @click="downloadOrdenCompra"
            :loading="loadingButtonOrdenCompra"
            color="primary"
            width="200"
            class="btn-primary-global ml-2 mt-2"
          >
            Descargar O.C.
          </v-btn>
          <!-- #endregion Descargar O.C. -->

          <!-- #region Enviar para aprobación -->
          <v-btn
            v-if="
              statusOrder === 'Borrador' ||
                statusOrder === 'Correcciones' ||
                !AddOrEdit
            "
            width="220"
            color="primary"
            class="btn-primary-global mt-2"
            :disabled="butttonGuardarAprobacion"
            :loading="butttonGuardarAprobacion"
            @click="setBorradorOAprobacion(1)"
          >
            Enviar para aprobación
          </v-btn>
          <!-- #endregion Enviar para aprobación -->

          <!-- #region editar información -->

          <div
            class="d-flex justify-end"
            v-if="AddOrEdit && !bBorrador && !bCorreciones"
          >
            <v-btn
              v-if="bAdminPermission"
              @click="setEditInformation"
              :loading="bLoadingEditInformation"
              :disabled="bLoadingEditInformation"
              color="primary"
              width="200"
              class="btn-primary-global ml-2 mt-2"
            >
              {{ bModifyOrder ? "Guardar Información" : "Editar información" }}
            </v-btn>
          </div>
          <!-- #endregion editar información -->
        </div>
      </v-col>
    </v-row>

    <v-row v-if="bModuleOCGestion">
      <v-col cols="12" sm="4">
        <v-btn
          v-if="bModifyOrder || !AddOrEdit"
          @click="setCancelEditInformation"
          color="black"
          width="150"
          outlined
          class="btn-secundary-global mr-2 mt-2"
          >Cancelar
        </v-btn>
        <!-- <v-btn
          color="black"
          width="175"
          outlined
          class="btn-secundary-global mr-2 mt-2"
          :disabled="disableBtnDownloadDoc"
          @click="downloadCotizacion"
          :loading="loadingButtonCotizacion"
          >Descargar cotización
        </v-btn> -->
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <div>
          <v-btn
            color="black"
            width="175"
            outlined
            class="btn-secundary-global mr-2 mt-2"
            @click="rechazarOrden"
            >Enviar a correción
          </v-btn>
          <v-btn
            width="175"
            color="primary"
            class="btn-primary-global mt-2"
            @click="aceptarOrden"
            :loading="loadingButtonAceptar"
            >Aceptar
          </v-btn>
          <v-btn
            v-if="bAdminPermission"
            @click="setEditInformation"
            :loading="bLoadingEditInformation"
            :disabled="bLoadingEditInformation"
            color="primary"
            width="200"
            class="btn-primary-global ml-2 mt-2"
          >
            {{ bModifyOrder ? "Guardar Información" : "Editar información" }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- #endregion buttons section -->

    <DenyOrder
      @close="openDialogDenyOrder = false"
      :openDialogDenyOrder="openDialogDenyOrder"
      :itemDetailsDeny="oItemOrderOrigin"
    ></DenyOrder>

    <dialog-edit-purchase-order
      @setDialogEditPurchaseOrder="setDialogEditPurchaseOrder"
      :bDialogEditPurchaseOrder="bDialogEditPurchaseOrder"
      :oDialogEditPurchaseOrder="oItemOrderOrigin"
    />
  </div>
</template>
<script>
import moment from "moment";
import DialogEditPurchaseOrder from "./DialogEditPurchaseOrder.vue";
import DenyOrder from "@/components/views/OCs/DenyOrder";

export default {
  props: {
    bAddOrEdit: Boolean,
    bEditMaterials: Boolean,
    bReadonlyMaterials: Boolean,
    bEditMaterialsAll: Boolean,
  },
  data() {
    return {
      bAdminPermission: false,
      bLoading: false,
      oItemOrderOrigin: {},
      itemsRequestPerMaterial: [],
      statusOrder: "",
      formDate: true,
      expand2: false,
      loadingButtonCotizacion: false,
      loadingButtonOrdenCompra: false,
      loadingSendEmail: false,
      loadingConfirmReceived: false,
      disableBtnDownloadDoc: false,
      aModuleProyect: ["ProyectsDetailOC", "ProyectDetailCategoryDetailOC"],
      aModuleProvider: ["SupplierDetailOC"],
      aModuleOC: ["OrdersAddOrDetail"],
      bDialogEditPurchaseOrder: false,
      oDialogEditPurchaseOrder: {},

      // #region Formulario de orden de compra

      sNameBarProyect: "",
      dPercentOCProyect: 0,
      sColorOCProyect: "",
      dSpentOCProyect: 0,
      dBudgetOCProyect: 0,

      sNameBarCategorie: "",
      dPercentOCCategorie: 0,
      sColorOCCategorie: "",
      dSpentOCCategorie: 0,
      dBudgetOCCategorie: 0,

      oItemPurchaseOrder: {},

      // #region Componente de proyecto
      sIdProyect: "",
      sProyect: null,
      aProyect: [],
      bLoadingProyect: true,
      bDisabledProyect: true,
      // #endregion Componente de proyecto

      // #region Componente de categoría
      sIdCategorie: "",
      sCategorie: null,
      aCategorie: [],
      bLoadingCategorie: false,
      bDisabledCategorie: true,
      // #endregion Componente de categoría

      // #region Componente de proveedores
      sIdProvider: "",
      sProvider: null,
      aProvider: [],
      bLoadingProvider: false,
      bDisabledProvider: true,
      // #endregion Componente de proveedores

      // #region Componente de metodo de pago
      sIdPaymentMethod: "",
      sPaymentMethod: null,
      aPaymentMethod: [],
      bLoadingPaymentMethod: false,
      bDisabledPaymentMethod: true,
      // #endregion Componente de metodo de pago

      // #region componente notas para el proveedor
      sProviderNote: "",
      // #endregion componente notas para el proveedor

      // #region componente notas internas
      sInternalNote: "",
      // #endregion componente notas internas

      // #endregion Formulario de orden de compra

      // --- variables anadir button -- //
      sConceptAdd: "",
      sQuantityAdd: "",
      sUnitMeasureAdd: "",
      dUnitPriceAdd: "",
      aUnitsMeasure: [],
      aStatus: [],
      butttonGuardarBorrador: false,
      butttonGuardarAprobacion: false,
      comnetariosRechazo: "",
      xsWidth: false,
      filelist: null, // Store our uploaded files
      sPDFName: "",

      bCorreciones: false,
      bBorrador: false,
      bAprobada: false,
      bRecibida: false,
      bPagada: false,
      bEnviada: false,
      bRecibidoParcial: false,

      openDialogDenyOrder: false,
      loadingButtonAceptar: false,

      bEditFile: true,
      bFile: false,
      sUrlFile: "",
      aFileQuotation: [],
      bDisabledFileQuotation: true,
      bLoadingFileQuotation: true,
      bModifyFile: false,

      bModifyOrder: false,
      bLoadingEditInformation: false,

      bAdvancePurchase: false,
      sAdvancePurchase: "",
      aAdvancePurchase: [
        // {
        //   sId: "1",
        //   sName: "Concepto 1",
        //   dQuantity: "",
        //   sMeasureUnitId: "623ba552-a43b-45dd-8f0a-4278a659ab7d",
        //   sMeasureUnitName: "bulto",
        //   dUnitPrice: 40000,
        // },
        // {
        //   sId: "2",
        //   sName: "Concepto 2",
        //   dQuantity: "",
        //   sMeasureUnitId: "f13ce271-2ee5-49f9-9f32-fb172bbdb2c7",
        //   sMeasureUnitName: "caja",
        //   dUnitPrice: 20000,
        // },
      ],
      oAdvancePurchase: {},
      oOptionsCurrency: {
        locale: "en-US",
        prefix: "$",
        suffix: "MXN",
        length: 11,
        precision: 2,
      },
      sInventory: "",
      oOptionUnit: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 2,
      },
    };
  },
  beforeMount() {
    this.getProyects();
    this.getUnits();
    if (this.AddOrEdit) {
      this.$store
        .dispatch("getAdminPermission")
        .then((bAdminPermission) => {
          this.bAdminPermission = bAdminPermission;
          this.getPurchaseOrderById();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.bEditFile = true;
    }
  },
  methods: {
    setDialogEditPurchaseOrder() {
      this.$store
        .dispatch("getAdminPermission")
        .then((bAdminPermission) => {
          if (bAdminPermission) {
            this.bDialogEditPurchaseOrder = !this.bDialogEditPurchaseOrder;
            if (this.bDialogEditPurchaseOrder) {
              this.oDialogEditPurchaseOrder = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // #region catalagos de dropdowns
    getProyects() {
      db.get(`${uri}/api/v1/projects/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {},
      })
        .then((resp) => {
          this.aProyect = resp.data.projects.map((e) => {
            return {
              ...e,
              sId: e.sProjectId,
              sName: e.sName,
            };
          });
          // this.sIdProyect = this.oDialogEditPurchaseOrder.sProjectId;
          this.bLoadingProyect = false;
          if (this.sIdProyect) {
            this.getCategories();
          }
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getCategories() {
      this.bLoadingCategorie = true;
      db.get(`${uri}/api/v1/projects/${this.sIdProyect}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aCategorie = resp.data.project.Categories.map((e) => {
            return {
              sId: e.sCategoryId,
              sName: e.sCategoryName,
              dBudget: e.dBudget,
              dSpent: e.dSpent,
            };
          });
          // this.sIdCategorie = this.oDialogEditPurchaseOrder.sCategoryId;

          this.bLoadingCategorie = false;
          this.bDisabledCategorie = false;
          this.getProvider();
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getProvider() {
      this.bLoadingProvider = true;
      db.get(`${uri}/api/v1/supplier`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: 1,
          iItemsPerPage: 1,
        },
      })
        .then((resp) => {
          // this.sIdProvider = ""
          this.aProvider = resp.data.suppliers.map((e) => {
            return {
              sId: e.sSupplierId,
              sName: e.sCompanyName,
            };
          });
          // this.sIdProvider = this.oDialogEditPurchaseOrder.sSupplierId;
          this.bLoadingProvider = false;
          if (
            this.sIdPaymentMethod === "21f7a6a7-db5f-4d52-8472-3c3b8d613f27"
          ) {
            // Metodo de pago "Compra anticipada"
            if (this.AddOrEdit) {
              this.bDisabledProvider = true;
            }
          } else {
            this.bDisabledProvider = false;
          }

          this.getPaymenMethods();
          //   this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPaymenMethods() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aPaymentMethod = resp.data.paymentMethods.map((e) => {
            return {
              sId: e.sPaymentMethodId,
              sName: e.sName,
              disabled: false,
            };
          });
          if (this.AddOrEdit) {
            if (
              this.sIdPaymentMethod !== "21f7a6a7-db5f-4d52-8472-3c3b8d613f27"
            ) {
              this.aPaymentMethod.find(
                (e) => e.sId === "21f7a6a7-db5f-4d52-8472-3c3b8d613f27"
              ).disabled = true;
            }
          }
          this.bLoadingPaymentMethod = false;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // #endregion catalagos de dropdowns

    setCancelEditInformation() {
      this.bModifyFile = false;
      if (!this.AddOrEdit || this.bCorreciones || this.bBorrador) {
        this.$router.push({
          name: "Orders",
        });
      } else {
        this.bModifyOrder = false;
        this.$store.commit("refresher", true);
      }
    },
    setEditInformation() {
      if (!this.bModifyOrder) {
        this.bModifyOrder = true;
      } else {
        this.setChangeOrder();
      }
    },
    setChangeOrder() {
      this.bLoadingEditInformation = true;
      const payload = {
          sProjectId: this.sIdProyect,
          sCategoryId: this.sIdCategorie,
          sSupplierId: this.sIdProvider,
          sPaymentMethodId: this.sIdPaymentMethod,
          sPrivateComments: this.sInternalNote,
          sPurchaseOrderStatusId: this.oItemPurchaseOrder
            .PurchaseOrderStatusRecords.sPurchaseOrderStatusId,
          sComments: this.sProviderNote,
          aOrderMaterials: this.itemsRequestPerMaterial.map((se) => {
            return {
              sOrderMaterialId: se.sOrderMaterialId,
              dQuantity: parseFloat(se.dQuantity).toFixed(2),
              sMeasureUnitId: se.sMeasureUnitId,
              sName: se.sMaterialName,
              dUnitPrice: Number(se.dUnitPrice),
            };
          }),
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      db.patch(
        `${uri}/api/v1/purchase_order/update/${this.$route.params.sOCId}`,
        payload,
        config
      )
        .then((response) => {
          if (this.bModifyFile) {
            var form = new FormData();
            form.append("file", this.filelist);

            const config2 = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("user-tk"),
                contentType: "application/pdf",
              },
            };

            db.post(
              `${uri}/api/v1/purchase_order/${this.$route.params.sOCId}/uploadPDF`,
              form,
              config2
            )
              .then((resp) => {
                this.bLoadingEditInformation = false;
                const alert = {
                  color: "green",
                  text: response.data.message,
                };
                this.$store.commit("toggle_alert", alert);
                this.$store.commit("refresher", true);
                this.setCancelEditInformation();
              })
              .catch((err) => {
                this.bLoadingEditInformation = false;
                const alert = {
                  color: "red",
                  text: err.response.data.message,
                };
                this.$store.commit("toggle_alert", alert);
              });
          } else {
            this.bLoadingEditInformation = false;
            const alert = {
              color: "green",
              text: response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
            this.$store.commit("refresher", true);
            this.setCancelEditInformation();
          }
        })
        .catch((err) => {
          this.bLoadingEditInformation = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPurchaseOrderById() {
      db.get(`${uri}/api/v1/purchase_order/order/${this.$route.params.sOCId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {},
      })
        .then((resp) => {
          this.setFillData(resp.data.order);

          this.oItemPurchaseOrder = resp.data.order;
          this.getProyects();
          this.disableBtnDownloadDoc =
            resp.data.order.sQuoteKey ===
            "No existe una cotización adjunta por el momento.";
          this.statusOrder = resp.data.order.PurchaseOrderStatusRecords.sName;

          if (
            this.statusOrder === "Borrador" ||
            this.statusOrder === "Correcciones"
          ) {
            this.bModifyOrder = true;
          }

          this.bFile =
            resp.data.order.sQuoteKey ===
            "No existe una cotización adjunta por el momento."
              ? false
              : true;
          this.sUrlFile = this.bFile ? resp.data.order.sQuoteKey : null;
          if (
            this.statusOrder == "Aprobada" ||
            this.statusOrder == "Recibido Parcial"
          ) {
            this.formDate = false;
          } else {
            this.formDate = true;
          }
          this.getStatusOC(
            resp.data.order.PurchaseOrderStatusRecords.sPurchaseOrderStatusId
          );
          if (this.bBorrador || this.bCorreciones) {
            this.getUnits();
          }
          this.$emit("setStatusOC", {
            statusOrder: this.statusOrder,
            sPurchaseOrderPaymentStatus:
              resp.data.order.sPurchaseOrderPaymentStatus,
          });
          this.oItemOrderOrigin = resp.data.order;
          this.oItemOrderOrigin.tDate = this.oItemOrderOrigin.tDate.substr(
            0,
            10
          );
          this.comnetariosRechazo = this.oItemOrderOrigin.PurchaseOrderStatusRecords.sComments;
          this.$store.commit("refresher", false);
          this.getMaterialsByOC();
          this.getStatus();
          this.bModifyFile = false;
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMaterialsByOC() {
      this.bLoading = true;
      // get material per request
      db.get(
        `${uri}/api/v1/purchase_order/materials/${this.$route.params.sOCId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            formatDate: this.formDate,
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;
          var i = 0;
          var date = moment.utc().format();
          this.itemsRequestPerMaterial = resp.data.OrderMaterials.map((e) => {
            i++;
            return {
              ...e,
              dQuantity: Number(e.dQuantity),
              // Modificar quantity de la primera condicion por la nueva variable
              dReceivedAmount:
                this.statusOrder != "Recibida"
                  ? e.dQuantityReceived != null
                    ? Number(e.dQuantityReceived)
                    : Number(0)
                  : e.dQuantityReceived != null
                  ? Number(e.dQuantityReceived)
                  : "N/A",
              // Agregar campo nuevo tipo boolean
              bReceived: e.bReceived,
              // Cambiar hardcore de campo por columna nueva de fecha de recepcion
              dReceivedDate:
                this.statusOrder != "Recibida"
                  ? e.tReceivedDate != null
                    ? e.tReceivedDate.substr(0, 11).replace("T", "")
                    : moment
                        .utc(date)
                        .local()
                        .format("YYYY-MM-DD")
                  : e.tReceivedDate != null
                  ? e.tReceivedDate.substr(0, 11)
                  : "N/A",
              bMenuFinal: false,
            };
          });
          this.sumatoriaMateriales();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getConceptByProvider(sId) {
      db.get(`${uri}/api/v1/supplier/${sId}/products`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: 1,
          bList: true,
        },
      })
        .then((resp) => {
          this.aAdvancePurchase = resp.data.results.map((e) => {
            return {
              sId: e.sSupplierProductId,
              sName: e.sName,
              dQuantity: e.dUsedQuantity,
              sMeasureUnitId: e.sMeasureUnitId,
              sMeasureUnitName: e.sMeasureUnitName,
              dUnitPrice: e.dUnitPrice,
              sInventory: e.sInventory,
              oOptionUnit: {
                locale: "en-US",
                prefix: "",
                suffix: "/" + e.sInventory, //sInventory
                length: 11,
                precision: 2,
              },
            };
          });
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    setFillData(oItem) {
      this.setFillProccessBarProyect(oItem);
      this.setFillProccessBarCategorie(oItem);
      this.sIdProyect = oItem.sProjectId;
      this.sIdCategorie = oItem.sCategoryId;
      this.sNameBarCategorie = oItem.sCategoryName;
      this.sIdProvider = oItem.sSupplierId;
      this.sIdPaymentMethod = oItem.sPaymentMethodId;
      this.sProviderNote = oItem.sComments;
      this.sInternalNote = oItem.sPrivateComments;
      if (
        oItem.PurchaseOrderStatusRecords.sPurchaseOrderStatusId ===
          "b78213ee-0b4f-4019-b770-0a19dbbe2ccd" ||
        oItem.PurchaseOrderStatusRecords.sPurchaseOrderStatusId ===
          "1396dcd2-db88-4e0e-904a-28c3460e1d7e"
      ) {
        this.bModifyOrder = true;
      } else {
        this.bModifyOrder = false;
      }
    },
    setFillProccessBarProyect(oItem) {
      this.dSpentOCProyect = parseFloat(oItem.oProjectInfo.dSpent);
      this.dBudgetOCProyect = parseFloat(oItem.oProjectInfo.dBudget);
      this.dPercentOCProyect = this.getPercentBar(
        this.dSpentOCProyect,
        this.dBudgetOCProyect
      );
      this.sColorOCProyect = this.getColorPercent(this.dPercentOCProyect);
    },
    setFillProccessBarCategorie(oItem) {
      this.dSpentOCCategorie = parseFloat(oItem.oCategoryInfo.dSpent);
      this.dBudgetOCCategorie = parseFloat(oItem.oCategoryInfo.dBudget);
      this.dPercentOCCategorie = this.getPercentBar(
        this.dSpentOCCategorie,
        this.dBudgetOCCategorie
      );
      this.sColorOCCategorie = this.getColorPercent(this.dPercentOCCategorie);
    },

    // #region porcent y color porcent barra
    getPercentBar(presupuestoInicial, presupuestoFinal) {
      const dPresupuestoInicial = Number(presupuestoInicial),
        dPresupuestoFinal = Number(presupuestoFinal);
      var res = 0;
      if (dPresupuestoFinal > 1) {
        res = parseFloat(
          (dPresupuestoInicial / dPresupuestoFinal) * 100
        ).toFixed(2);
      }
      return res;
    },
    getColorPercent(percent) {
      percent = Number(percent);

      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },
    // #endregion porcent y color porcent barra

    setFillPayloadNewPurchaseOrder() {
      if (this.sIdPaymentMethod === "21f7a6a7-db5f-4d52-8472-3c3b8d613f27") {
        return this.itemsRequestPerMaterial.map((se) => {
          return {
            sSupplierProductId: se.sAdvancePurchaseId,
            dQuantity: se.dQuantity,
            sMeasureUnitId: se.sMeasureUnitId,
            sName: se.sMaterialName,
            dUnitPrice: se.dUnitPrice,
          };
        });
      } else {
        return this.itemsRequestPerMaterial.map((se) => {
          return {
            dQuantity: se.dQuantity,
            sMeasureUnitId: se.sMeasureUnitId,
            sName: se.sMaterialName,
            dUnitPrice: se.dUnitPrice,
          };
        });
      }
    },
    setCreateNewPruchaseOrder(status) {
      const payload = {
          sProjectId: this.sIdProyect,
          sCategoryId: this.sIdCategorie,
          sSupplierId: this.sIdProvider,
          sPaymentMethodId: this.sIdPaymentMethod,
          sPrivateComments: this.sInternalNote ? this.sInternalNote : "",
          iPurchaseOrderStatusType: status,
          sComments: this.sProviderNote ? this.sProviderNote : "",
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      payload["aOrderMaterials"] = this.setFillPayloadNewPurchaseOrder();

      db.post(`${uri}/api/v1/purchase_order`, payload, config)
        .then((response) => {
          let form = new FormData();
          let file = this.filelist;
          form.append("file", file);

          const config2 = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              contentType: "application/pdf",
            },
          };
          if (response.status == 201) {
            db.post(
              `${uri}/api/v1/purchase_order/${response.data.orderMaterial.sPurchaseOrderId}/uploadPDF`,
              form,
              config2
            )
              .then((resp) => {
                const alert = {
                  color: "green",
                  text: resp.data.message,
                };
                this.$store.commit("toggle_alert", alert);
                this.$router.push({
                  name: "Orders",
                });
              })
              .catch((err) => {
                this.bLoading = false;
                this.lodingButtonBorrador = false;
                this.lodingButtonAprobacion = false;
                this.butttonGuardarAprobacion = false;

                const alert = {
                  color: "red",
                  text: err.response.data.message,
                };
                this.$store.commit("toggle_alert", alert);
              });
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.lodingButtonBorrador = false;
          this.lodingButtonAprobacion = false;
          this.butttonGuardarAprobacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setBorradorOAprobacion(status) {
      var newStatus = "",
        newStatusId = "";

      if (status == 1) {
        newStatus = "Enviada";
        this.butttonGuardarAprobacion = true;
      } else if (status == 2) {
        newStatus = "Borrador";
        this.butttonGuardarBorrador = true;
      }

      if (!this.AddOrEdit) {
        this.setCreateNewPruchaseOrder(status);
      } else {
        for (let i = 0; i < this.aStatus.length; i++) {
          const element = this.aStatus[i];
          if (element.sName == newStatus) {
            newStatusId = element.sPurchaseOrderStatusId;
          }
        }
        const payload = {
            sComments: this.comentarios,
            sPrivateComments: this.comentariosInternos,
            aOrderMaterials: this.itemsRequestPerMaterial.map((se) => {
              return {
                dQuantity: se.dQuantity,
                sMeasureUnitId: se.sMeasureUnitId,
                sName: se.sMaterialName,
                dUnitPrice: se.dUnitPrice,
              };
            }),
            sPurchaseOrderStatusId: newStatusId,
          },
          config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              contentType: "application/x-www-form-urlencoded;charset=utf-8",
            },
          };

        //post credentials and get access token from laravel backend
        db.post(
          `${uri}/api/v1/purchase_order/${this.$route.params.sOCId}/draft`,
          payload,
          config
        )
          .then((response) => {
            if (response.status == 201) {
              if (this.filelist !== null) {
                var form = new FormData();
                form.append("file", this.filelist);

                const config2 = {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("user-tk"),
                    contentType: "application/pdf",
                  },
                };

                db.post(
                  `${uri}/api/v1/purchase_order/${response.data.order.sPurchaseOrderId}/uploadPDF`,
                  form,
                  config2
                )
                  .then((resp) => {
                    this.butttonGuardarBorrador = false;
                    this.butttonGuardarAprobacion = false;
                    const alert = {
                      color: "green",
                      text: response.data.message,
                    };
                    this.$store.commit("toggle_alert", alert);
                    this.$store.commit("refresher", true);
                  })
                  .catch((err) => {
                    this.butttonGuardarBorrador = false;
                    this.butttonGuardarAprobacion = false;
                    this.bLoading = false;
                    const alert = {
                      color: "red",
                      text: err.response.data.message,
                    };
                    this.$store.commit("toggle_alert", alert);
                  });
              } else {
                this.butttonGuardarBorrador = false;
                this.butttonGuardarAprobacion = false;
                const alert = {
                  color: "green",
                  text: response.data.message,
                };
                this.$store.commit("toggle_alert", alert);
                this.$store.commit("refresher", true);
              }
            }
          })
          .catch((err) => {
            this.bLoading = false;
            this.butttonGuardarBorrador = false;
            this.butttonGuardarAprobacion = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },

    setFileInput() {
      document.getElementById("idFileInput").click();
    },
    setDeleteFile() {
      this.bFile = false;
    },
    setDownloadFile() {
      window.open(this.sUrlFile, "blank_");
    },
    aceptarOrden() {
      this.loadingButtonAceptar = true;

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        sPurchaseOrderStatusId: "428d180d-0f98-469b-a6e8-ade5021646de",
        sComments: "Sin comentarios",
      };
      db.put(
        `${uri}/api/v1/purchase_order/${this.$route.params.sOCId}`,
        params,
        config
      )
        .then((resp) => {
          this.loadingButtonAceptar = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          // this.$store.commit("refresher", true);
          this.$router.push({ name: "OCs" });
        })
        .catch((err) => {
          this.loadingButtonAceptar = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getStatusOC(sStatusId) {
      this.bAprobada = false;
      this.bRecibidoParcial = false;
      this.bEnviada = false;
      this.bPagada = false;
      this.bRecibida = false;
      this.bBorrador = false;
      this.bCorreciones = false;
      switch (sStatusId) {
        case "428d180d-0f98-469b-a6e8-ade5021646de": //Aprobada
          this.bAprobada = true;
          break;

        case "0f0b5152-8044-4714-b8d6-b706559383c8": //Recibido parcial
          this.bRecibidoParcial = true;
          break;

        case "db719f76-790e-4094-a7e3-5e8317b0beb6": //Envidada
          this.bEnviada = true;
          break;

        case "b17505a2-c7d4-4bbd-9cad-07dd23092199": //Pagada
          this.bPagada = true;
          break;

        case "0808c4e2-3b2d-4415-bbe9-8c4882808513": //Recibida
          this.bRecibida = true;
          break;

        case "b78213ee-0b4f-4019-b770-0a19dbbe2ccd": //Borrador
          this.bBorrador = true;
          break;
        case "1396dcd2-db88-4e0e-904a-28c3460e1d7e": //Correciones
          this.bCorreciones = true;
          break;

        default:
          break;
      }
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(sQuantityAdd) {
      if (sQuantityAdd !== "") {
        if (sQuantityAdd <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.sQuantityAdd = "";
        }
      }
    },
    rechazarOrden() {
      this.openDialogDenyOrder = !this.openDialogDenyOrder;
      this.$emit("openDialogDenyOrderFunction", this.oItemOrderOrigin);
    },
    setUpdateCategory() {
      const param = {
        sCategoryId: this.sIdCategorie,
        sPurchaseOrderId: this.$route.params.sOCId,
      };
      db.patch(`${uri}/api/v1/purchase_order/category/`, param, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/json",
        },
      })
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    // obtiene la fecha hora y zona
    validateReceivedAmount(item) {
      if (item.dQuantity < item.dReceivedAmount) {
        item.dReceivedAmount = item.dQuantity;
        const alert = {
          color: "red",
          text:
            "Es necesario agregar una cantidad recibida menor o igual a la cantidad",
        };
        this.$store.commit("toggle_alert", alert);
      } else if (item.dReceivedAmount == "") {
        item.dReceivedAmount = item.dQuantity;
        const alert = {
          color: "red",
          text: "Es necesario agregar una cantidad recibida",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    sumatoriaImporte(e) {
      var sum = 0;
      sum = sum + Number(e.dQuantity) * Number(e.dUnitPrice);
      return this.mask(sum);
    },
    sumatoriaMaterialesRecibidos() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + Number(element.dReceivedAmount) * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    upDateData(item) {
      const payload = {
          // sPurchaseOrderId : this.idOrder,
          bReceived: item.bReceived,
          dQuantityReceived: parseFloat(item.dReceivedAmount).toFixed(2),
          tReceivedDate: item.dReceivedDate,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/json;charset=utf-8",
          },
        };
      if (item.dQuantity < item.dReceivedAmount) {
        const alert = {
          color: "red",
          text:
            "Es necesario agregar una cantidad recibida menor o igual a la cantidad.",
        };
        this.$store.commit("toggle_alert", alert);
        item.dReceivedAmount = item.dQuantity;
        return false;
      } else {
        db.put(
          `${uri}/api/v1/order_materials/${item.sOrderMaterialId}`,
          payload,
          config
        )
          .then((resp) => {
            const alert = {
              color: "green",
              text: resp.data.message,
            };
            this.$store.commit("toggle_alert", alert);
            this.$store.commit("refresher", true);
          })
          .catch((err) => {
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },
    getMaterialCompleto() {
      db.put(
        `${uri}/api/v1/purchase_order/${this.$route.params.sOCId}/delivered`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            ContentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.materialCompleto = false;
          this.refreshAndBack = true;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sendEmail() {
      this.loadingSendEmail = true;
      db.post(
        `${uri}/api/v1/purchase_order/emailTo/${this.$route.params.sOCId}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/json",
          },
        }
      )
        .then((resp) => {
          this.loadingSendEmail = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.loadingSendEmail = false;
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadCotizacion() {
      this.loadingButtonCotizacion = true;
      db.get(`${uri}/api/v1/purchase_order/order/${this.$route.params.sOCId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          if (resp.data.order.sQuoteKey !== "Sin documento por el momento.") {
            this.loadingButtonCotizacion = false;
            window.open(resp.data.order.sQuoteKey, "_blank");
          } else {
            this.loadingButtonCotizacion = false;
            const alert = {
              color: "red",
              text: "No existe un documento asociado a esta orden de compra.",
            };
            this.$store.commit("toggle_alert", alert);
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonCotizacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadOrdenCompra() {
      this.loadingButtonOrdenCompra = true;
      // get material per request
      db.get(`${uri}/api/v1/purchase_order/pdf/${this.$route.params.sOCId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.loadingButtonOrdenCompra = false;
          window.open(resp.data.pdfUrl, "_blank");
        })
        .catch((err) => {
          this.loadingButtonOrdenCompra = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    eliminarOrder() {
      var item = this.itemDetails;
      this.$store.commit("setDeleteItems", {
        id: this.$route.params.sOCId,
        route: "/api/v1/purchase_order/" + this.$route.params.sOCId,
        unit: "orden de compra.",
        bReturn: true,
        sToReturn: "Orders",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    deleteItemMaterial(e) {
      if (this.AddOrEdit) {
        if (this.itemsRequestPerMaterial.length > 1) {
          this.itemsRequestPerMaterial.splice(e, 1);
        } else {
          this.bLoading = false;
          const alert = {
            color: "red",
            text:
              "No puede eliminar todos los materiales de la orden de compra.",
          };
          this.$store.commit("toggle_alert", alert);
        }
      } else {
        this.itemsRequestPerMaterial.splice(e, 1);
      }
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.aUnitsMeasure.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    getUnits() {
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aUnitsMeasure = resp.data.units;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setMaterial() {
      let bSetMaterial = false;
      if (this.bAdvancePurchase) {
        if (
          Number(
            this.sInventory.replaceAll(",", "") > Number(this.sQuantityAdd)
          )
        ) {
          bSetMaterial = true;
        }
      } else {
        bSetMaterial = true;
      }

      if (bSetMaterial) {
        const arr = {
          sAdvancePurchaseId: this.bAdvancePurchase
            ? this.sAdvancePurchase
            : null,
          sMaterialName: this.sConceptAdd,
          dQuantity: parseFloat(this.sQuantityAdd).toFixed(2),
          sMeasureUnitId: this.sUnitMeasureAdd,
          sMeasureUnitName: this.unidadMedidaMaterialText,
          dUnitPrice: this.dUnitPriceAdd,
          sInventory: this.sInventory,
        };

        this.itemsRequestPerMaterial.push(arr);
        this.clearItemsMateriales();
      } else {
        const alert = {
          color: "red",
          text: "No es posible agregar material: Inventario no disponible.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    clearItemsMateriales() {
      this.sConceptAdd = "";
      this.sInventory = "";
      this.sAdvancePurchase = "";
      this.sQuantityAdd = "";
      this.sUnitMeasureAdd = "";
      this.unidadMedidaMaterialText = "";
      this.dUnitPriceAdd = "";
      this.oAdvancePurchase = {};
      this.oOptionUnit = {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 2,
      };
      this.sInventory = "";
    },
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aStatus = resp.data.orderStatus;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getFormatMoney(iNumber) {
      let convert_number = Number(iNumber).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    AddOrEdit() {
      return this.$route.params.sOCId !== "Add"; //false is Add , True is Edit
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    bModuleProyect() {
      return (
        this.aModuleProyect.filter((e) => e === this.$route.name).length > 0
      );
    },
    bModuleProvider() {
      return (
        this.aModuleProvider.filter((e) => e === this.$route.name).length > 0
      );
    },
    bModuleOC() {
      return this.aModuleOC.filter((e) => e === this.$route.name).length > 0;
    },
    bModuleOCGestion() {
      return this.$route.name === "DetailsOCGestion";
    },
    // bAdminPermission() {
    //   return this.$store.state.bAdminPermission;
    // },
    validateAnadir() {
      return (
        this.sConceptAdd !== "" &&
        this.sQuantityAdd !== "" &&
        this.sUnitMeasureAdd !== "" &&
        this.dUnitPriceAdd !== ""
      );
    },
  },
  watch: {
    refreshTable: function() {
      this.getPurchaseOrderById();
    },
    sIdProyect() {
      if (this.sIdProyect) {
        this.sIdCategorie = null;
        if (this.aProyect.length > 0) {
          this.sNameBarProyect = this.aProyect.find(
            (e) => e.sId === this.sIdProyect
          ).sName;
          this.dSpentOCProyect = this.aProyect.find(
            (e) => e.sId === this.sIdProyect
          ).dSpent;
          this.dBudgetOCProyect = this.aProyect.find(
            (e) => e.sId === this.sIdProyect
          ).dBudget;

          this.dPercentOCProyect = this.getPercentBar(
            this.dSpentOCProyect,
            this.dBudgetOCProyect
          );
          this.sColorOCProyect = this.getColorPercent(this.dPercentOCProyect);
        }
      } else {
        this.sIdCategorie = null;
      }
    },
    sIdCategorie() {
      if (this.sIdCategorie) {
        if (this.aCategorie.length > 0) {
          if (!this.bModifyOrder) {
            if (this.bModuleOCGestion) {
              this.setUpdateCategory();
            }
          }
          this.sNameBarCategorie = this.aCategorie.find(
            (e) => e.sId === this.sIdCategorie
          ).sName;
          this.dSpentOCCategorie = this.aCategorie.find(
            (e) => e.sId === this.sIdCategorie
          ).dSpent;
          this.dBudgetOCCategorie = this.aCategorie.find(
            (e) => e.sId === this.sIdCategorie
          ).dBudget;

          this.dPercentOCCategorie = this.getPercentBar(
            this.dSpentOCCategorie,
            this.dBudgetOCCategorie
          );
          this.sColorOCCategorie = this.getColorPercent(
            this.dPercentOCCategorie
          );
        }
      } else {
        this.sNameBarCategorie = "";
        this.dSpentOCCategorie = 0;
        this.dBudgetOCCategorie = 0;
        this.dPercentOCCategorie = 0;
        this.sColorOCCategorie = this.getColorPercent(0);
      }
    },
    sIdProvider() {
      if (this.sIdProvider) {
        if (this.sIdPaymentMethod === "21f7a6a7-db5f-4d52-8472-3c3b8d613f27") {
          // Metodo de pago "Compra anticipada"
          if (this.AddOrEdit) {
            this.bDisabledProvider = true;
          }
          this.sIdPaymentMethod = null;
          this.getConceptByProvider(this.sIdProvider);
        }
        this.bDisabledPaymentMethod = false;
      } else {
        this.bDisabledPaymentMethod = true;
      }
    },
    sIdPaymentMethod() {
      if (this.sIdPaymentMethod === "21f7a6a7-db5f-4d52-8472-3c3b8d613f27") {
        // Metodo de pago "Compra anticipada"
        this.bAdvancePurchase = true;
        if (this.AddOrEdit) {
          this.bDisabledPaymentMethod = true;
        }
        this.getConceptByProvider(this.sIdProvider);
      } else {
        this.bAdvancePurchase = false;
        this.itemsRequestPerMaterial = [];
        this.clearItemsMateriales();
      }
    },
    sAdvancePurchase() {
      if (this.sAdvancePurchase) {
        this.oAdvancePurchase = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        );
        this.sConceptAdd = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).sName;
        this.sUnitMeasureAdd = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).sMeasureUnitId;
        this.unidadMedidaMaterialText = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).sMeasureUnitName;
        this.dUnitPriceAdd = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).dUnitPrice;
        this.sInventory = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).sInventory;
        this.oOptionUnit = this.aAdvancePurchase.find(
          (e) => e.sId === this.sAdvancePurchase
        ).oOptionUnit;
      } else {
        this.oAdvancePurchase = {};
        this.sConceptAdd = "";
        this.sInventory = "";
        this.sUnitMeasureAdd = "";
        this.unidadMedidaMaterialText = "";
        this.dUnitPriceAdd = "";
      }
    },
    oItemPurchaseOrder: {
      deep: true,
      handler() {
        this.setFillData(this.oItemPurchaseOrder);
      },
    },
    itemsRequestPerMaterial: {
      deep: true,
      handler() {},
    },
    filelist: {
      deep: true,
      handler() {
        this.bModifyFile = true;
      },
    },
  },
  components: { DialogEditPurchaseOrder, DenyOrder },
};
</script>
<style>
/* #region Estilo de barra */

.content-porcentual-bar-o-c-dialog {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  align-items: center;
  margin-top: 10px;
}
.content-porcentual-bar-dialog {
  position: relative;
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0px 15px;
}
.porcentual-bar-o-c-dialog {
  height: 20px;
  width: 100%;
  border-radius: 40px;
  margin-top: 5px;
}
.txt-spent-budget-o-c-dialog {
  position: absolute;
  top: 2px;
  left: 3px;
  margin-bottom: 0px !important;
  margin-top: 0px;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 15px;
}
.txt-porcent-oc-dialog {
  margin-bottom: 0px !important;
  margin-top: 9px;
  margin-left: 10px;
}
/* #endregion Estilo de barra */

.simple-table-materials {
  /* max-height: 300px !important; */
}
.td-dReceivedAmount {
  min-width: 100px;
}
.td-dReceivedDate {
  min-width: 170px;
}
.content-porcentual-bar-o-c-all {
  box-shadow: 0px 3px 6px #00000029;
  /* height: 70px; */
  border-radius: 24px;
  align-items: center;
  padding: 20px 15px;
}
.content-porcentual-bar-o-c-info {
  display: flex;
  width: 100%;
}
.content-porcentual-bar-o-c {
  display: flex;
  border-radius: 24px;
  align-items: center;
}

.txt-categorie-info-porcentual {
  font-size: 14px;
  font-weight: 600;
}
.txt-spent-info-porcentual {
  font-size: 14px;
  font-weight: 600;
  margin-right: 100px;
}
.txt-spent-money-info-percentual {
  font-size: 14px;

  font-weight: normal !important;
}
.txt-budget-info-porcentual {
  font-size: 14px;
  font-weight: 600;
}
.txt-budget-money-info-percentual {
  font-size: 14px;

  font-weight: normal !important;
}
.porcentual-bar-o-c {
  height: 20px;
  width: 100%;
  border-radius: 40px;
  margin-top: 5px;
}
.txt-spent-budget-o-c {
  font-weight: 600;
  margin-bottom: 0px !important;
  margin-top: 5px;
  margin-right: 10px;
}
.txt-porcent-oc {
  margin-bottom: 0px !important;
  margin-top: 9px;
  margin-left: 10px;
}

.content-file-oc {
  display: flex;
  align-items: center;
  border-bottom: solid #707070 1px;
}
.txt-view-file {
  color: #0093ff;
  text-decoration: underline;
  cursor: pointer;
}
.mdi-close-file {
  color: #ff7378;
  font-size: 25px !important;
}
.content-oc-global {
  padding: 10px 15px;
  border-top: 1px solid #e0e0e0;
}
.txt-title-global {
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px !important;
}
.txt-result-global {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #707070;
  margin-bottom: 0px !important;
}
.txt-empty-global {
  color: #707070 !important;
  font-style: italic !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-bottom: 0px;
}
.txt-subtotal-global {
  margin-bottom: 0px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.content-card-table-material-oc-global {
  border-radius: 20px !important;
  padding: 20px !important;
}

.divider-global {
  height: 1px !important;
  background-color: #e0e0e0 !important;
  width: 100%;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.border-b-divider-global {
  border-bottom: 3px solid #e4e4e4;
}
.content-Nowrap-global {
  white-space: nowrap;
}

.content-btn-actions-oc {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.input-table-materiales {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.input-table-materiales:focus {
  outline: 0;
}

.title-details-odc {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}
</style>
