<template>
  <v-dialog
    content-class="rounded-xl"
    v-model="bDialogEditPurchaseOrderEdit"
    persistent
    max-width="400"
  >
    <v-card class="content-dialog-edit-purchase-order">
      <v-card-title class="content-dialog-title-edit-purchase-order">
        Edición de información
      </v-card-title>
      <v-btn
        @click="setDialogEditPurchaseOrder"
        class="btn-close-dialog-edit-purchase-order "
        icon
        color="black"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-text class="content-dialog-body-edit-purchase-order">
        <div class="content-porcentual-bar-o-c-dialog">
          <div class="content-porcentual-bar-dialog">
            <p class="txt-spent-budget-o-c-dialog">
              Vista previa de gasto/presupuesto
            </p>
            <v-progress-linear
              class="porcentual-bar-o-c-dialog"
              height="25"
              :value="dPercentOC"
              buffer-value="100"
              :color="sColorOC"
            >
              ${{ getFormatMoney(dSpentOC) }} MXN / ${{
                getFormatMoney(dBudgetOC)
              }}
              MXN
            </v-progress-linear>
            <p class="txt-porcent-oc-dialog">
              {{ getPercentBar(dSpentOC, dBudgetOC) }}%
            </p>
          </div>
        </div>
        <v-select
          v-model="sIdProyect"
          :loading="bLoadingProyect"
          item-text="sName"
          item-value="sId"
          :items="aProyect"
          class="custom-placeholder"
          label="Proyecto"
          @change="getCategories"
          no-data-text="Sin información"
        >
        </v-select>
        <v-select
          v-model="sIdCategorie"
          :loading="bLoadingCategorie"
          item-text="sName"
          item-value="sId"
          :items="aCategorie"
          class="custom-placeholder"
          label="Categoría"
          no-data-text="Sin información"
        >
        </v-select>
        <v-select
          v-model="sIdProvider"
          :loading="bLoadingProvider"
          item-text="sName"
          item-value="sId"
          :items="aProvider"
          class="custom-placeholder"
          label="Proveedor"
          no-data-text="Sin información"
        >
        </v-select>
        <v-select
          v-model="sIdPaymentMethod"
          :loading="bLoadingPaymentMethod"
          item-text="sName"
          item-value="sId"
          :items="aPaymentMethod"
          class="custom-placeholder"
          label="Método de pago"
          no-data-text="Sin información"
        >
        </v-select>
        <v-textarea
          v-model="sProviderNote"
          rows="3"
          no-resize
          label="Notas para el proveedor"
        ></v-textarea>
        <v-textarea
          v-model="sInternalNote"
          rows="3"
          no-resize
          label="Notas internas"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <div class="content-dialog-actions-edit-purchase-order">
          <v-btn
            @click="setDialogEditPurchaseOrder"
            color="black"
            width="150"
            outlined
            class="btn-secundary-global"
          >
            Cancelar
          </v-btn>
          <v-btn
            :disabled="bDisabledBtnAction"
            width="150"
            color="primary"
            class="btn-primary-global"
            @click="setEditPurchaseOrder()"
          >
            Actualizar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogEditPurchaseOrder: Boolean,
    oDialogEditPurchaseOrder: Object,
  },
  data() {
    return {
      dPercentOC: 0,
      sColorOC: "",
      dSpentOC: 0,
      dBudgetOC: 0,

      bDialogEditPurchaseOrderEdit: false,

      sPurchaseOrderId: "",

      sStatusOrder: "",

      aFileQuotation: [],
      bLoadingFileQuotation: true,
      bDisabledFileQuotation: true,

      sIdProyect: "",
      sProyect: null,
      aProyect: [],
      bLoadingProyect: true,
      bDisabledProyect: true,

      sIdCategorie: "",
      sCategorie: null,
      aCategorie: [],
      bLoadingCategorie: true,
      bDisabledCategorie: true,

      sIdProvider: "",
      sProvider: null,
      aProvider: [],
      bLoadingProvider: true,
      bDisabledProvider: true,

      sIdPaymentMethod: "",
      sPaymentMethod: null,
      aPaymentMethod: [],
      bLoadingPaymentMethod: true,
      bDisabledPaymentMethod: true,

      sProviderNote: "",
      sInternalNote: "",

      bDisabledBtnAction: true,

      bEditFile: false,
      bFile: false,

      bModuleOCGestion: false,
    };
  },
  methods: {
    setDialogEditPurchaseOrder() {
      this.sIdProyect = "";
      this.bLoadingProyect = true;

      this.sIdCategorie = "";
      this.bLoadingCategorie = true;

      this.sIdProvider = "";
      this.bLoadingProvider = true;

      this.sIdPaymentMethod = "";
      this.bLoadingPaymentMethod = true;

      this.sProviderNote = "";
      this.sInternalNote = "";
      this.$emit("setDialogEditPurchaseOrder");
    },
    getData() {
      this.sProviderNote = this.oDialogEditPurchaseOrder.sPrivateComments;
      this.sInternalNote = this.oDialogEditPurchaseOrder.sComments;
      this.bDisabledBtnAction = false;
      this.bLoadingFileQuotation = false;
      this.bDisabledFileQuotation = false;
    },
    getProyects() {
      db.get(`${uri}/api/v1/projects/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {},
      })
        .then((resp) => {
          this.aProyect = resp.data.projects.map((e) => {
            return {
              sId: e.sProjectId,
              sName: e.sName,
            };
          });
          this.sIdProyect = this.oDialogEditPurchaseOrder.sProjectId;
          this.bLoadingProyect = false;
          this.getCategories();
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getCategories() {
      db.get(`${uri}/api/v1/projects/${this.sIdProyect}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aCategorie = resp.data.project.Categories.map((e) => {
            return {
              sId: e.sCategoryId,
              sName: e.sCategoryName,
              dBudget: e.dBudget,
              dSpent: e.dSpent,
            };
          });
          this.sIdCategorie = this.oDialogEditPurchaseOrder.sCategoryId;

          this.bLoadingCategorie = false;
          this.getProvider();
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getProvider() {
      db.get(`${uri}/api/v1/supplier`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: 1,
          iItemsPerPage: 1,
        },
      })
        .then((resp) => {
          // this.sIdProvider = ""
          this.aProvider = resp.data.suppliers.map((e) => {
            return {
              sId: e.sSupplierId,
              sName: e.sFullName,
            };
          });
          this.sIdProvider = this.oDialogEditPurchaseOrder.sSupplierId;
          this.bLoadingProvider = false;

          this.getPaymenMethods();
          //   this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getPaymenMethods() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aPaymentMethod = resp.data.paymentMethods.map((e) => {
            return {
              sId: e.sPaymentMethodId,
              sName: e.sName,
            };
          });
          this.sIdPaymentMethod = this.oDialogEditPurchaseOrder.sPaymentMethodId;
          this.bLoadingPaymentMethod = false;

          this.getData();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setEditPurchaseOrder() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        sProjectId: this.sIdProyect,
        sCategoryId: this.sIdCategorie,
        sSupplierId: this.sIdProvider,
        sPaymentMethodId: this.sIdPaymentMethod,
        sComments: this.sInternalNote,
        sPrivateComments: this.sProviderNote,
      };
      db.put(
        `${uri}/api/v1/purchase_order/${this.sPurchaseOrderId}/admin`,
        params,
        config
      )
        .then((resp) => {
          this.setDialogEditPurchaseOrder();
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonRechazar = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setFileInput() {
      // this.$refs.rFileInput.$el.click()
      this.$refs.rFileInput.$el.click;
    },
    getFormatMoney(iNumber) {
      let convert_number = Number(iNumber).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getPercentBar(presupuestoInicial, presupuestoFinal) {
      const dPresupuestoInicial = Number(presupuestoInicial),
        dPresupuestoFinal = Number(presupuestoFinal);
      var res = 0;
      if (dPresupuestoFinal > 1) {
        res = parseFloat(
          (dPresupuestoInicial / dPresupuestoFinal) * 100
        ).toFixed(2);
      }
      return res;
    },
    getColorPercent(percent) {
      percent = Number(percent);

      var color = "grey";

      if (percent <= 25.99) {
        color = "green";
      } else if (percent >= 26.0 && percent <= 50.99) {
        color = "yellow";
      } else if (percent >= 51.0 && percent <= 75.99) {
        color = "orange";
      } else if (percent >= 76.0) {
        color = "red";
      } else {
        color = "grey";
      }

      return color;
    },
  },
  watch: {
    bDialogEditPurchaseOrder() {
      this.bDialogEditPurchaseOrderEdit = this.bDialogEditPurchaseOrder;
      if (this.bDialogEditPurchaseOrderEdit) {
        this.bModuleOCGestion = this.$route.name === "DetailsOCGestion";
        this.sPurchaseOrderId = this.oDialogEditPurchaseOrder.sPurchaseOrderId;
        this.dSpentOC = parseFloat(
          this.oDialogEditPurchaseOrder.oCategoryInfo.dSpent
            ? this.oDialogEditPurchaseOrder.oCategoryInfo.dSpent
            : 0
        ).toFixed(2);
        this.bEditFile =
          this.oDialogEditPurchaseOrder.PurchaseOrderStatusRecords
            .sPurchaseOrderStatusId ===
            "b78213ee-0b4f-4019-b770-0a19dbbe2ccd" ||
          this.oDialogEditPurchaseOrder.PurchaseOrderStatusRecords
            .sPurchaseOrderStatusId === "1396dcd2-db88-4e0e-904a-28c3460e1d7e"
            ? true
            : false;
        this.bFile =
          this.oDialogEditPurchaseOrder.sQuoteKey ===
          "No existe una cotización adjunta por el momento."
            ? false
            : true;
        this.getProyects();
      }
    },
    sIdProyect() {
      this.sIdCategorie = null;
    },
    sIdCategorie() {
      if (this.sIdCategorie) {
        this.dSpentOC = this.aCategorie.find(
          (e) => e.sId === this.sIdCategorie
        ).dSpent;
        this.dBudgetOC = this.aCategorie.find(
          (e) => e.sId === this.sIdCategorie
        ).dBudget;
        

        this.dPercentOC = this.getPercentBar(this.dSpentOC, this.dBudgetOC);
        this.sColorOC = this.getColorPercent(this.dPercentOC);
      }
    },
  },
};
</script>
<style>
.content-dialog-edit-purchase-order {
  position: relative;
}
.content-dialog-title-edit-purchase-order {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ffcf2e;
  color: #000000;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.btn-close-dialog-edit-purchase-order {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px !important;
}
.content-dialog-body-edit-purchase-order {
  /* background-color: red; */
}
.content-dialog-actions-edit-purchase-order {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mdi-paperclip-file {
  cursor: pointer;
}

.content-porcentual-bar-o-c-dialog {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 24px;
  align-items: center;
  margin-top: 10px;
}
.content-porcentual-bar-dialog {
  position: relative;
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0px 15px;
}
.porcentual-bar-o-c-dialog {
  height: 20px;
  width: 100%;
  border-radius: 40px;
  margin-top: 5px;
}
.txt-spent-budget-o-c-dialog {
  position: absolute;
  top: 2px;
  left: 3px;
  margin-bottom: 0px !important;
  margin-top: 0px;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 15px;
}
.txt-porcent-oc-dialog {
  margin-bottom: 0px !important;
  margin-top: 9px;
  margin-left: 10px;
}
</style>
